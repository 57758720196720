






























































































































































































































































import Vue from 'vue';
import QuizEventApi from '@/apis/QuizEventApi';
import {
  MonitorMapValue,
  PushedQuizGroupRecord,
  QuizEventModelv4, SentDataRecord, TimeMapValue,
} from '@/apis/models/QuizEventModel';
import Auth from '@/libs/auth';
import BackButton from '@/components/BackButton.vue';
import TimeFunction from '@/libs/timeFunctions';
import { QuizGroupModel } from '@/apis/models/QuizGroupModel';
import QuizGroupApi from '@/apis/QuizGroupApi';
import WorkspaceObjectApi from '@/apis/WorkspaceObjectApi';
import QuizEventHandler from '@/handlers/QuizEventHandler';

export default Vue.extend({
  components: {
    BackButton,
  },
  data() {
    return {
      quizEvent: null as QuizEventModelv4 | null, // 本[測驗]的資訊
      nowTimestamp: Math.floor(new Date(Math.floor(Date.now())).getTime() / 1000), // 10位數
      mapData: {
        unsendDates: [] as string[],
        expiredDates: [] as string[],
        sentDataList: [] as SentDataRecord[],
      },
      scheduleSetting: {
        allowRepeat: true,
        repeatPeriod: 'week', // 'days'
        repeatFrequency: 1,
        weeklyScheduledDay: ['1'],
      },
      validData: {
        startTimeHour: 0,
        startTimeMin: 0,
        endTimeDay: 0,
        endTimeHour: 0,
      },
      timeMap: new Map<string, TimeMapValue>(),
      monitorMap: new Map<string, MonitorMapValue>(),

      showMemberGroups: false,
      showUnsend: false,
      showSent: false,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    quizEventId(): string {
      return this.$store.state.currentQuizEvent.uuid;
    },
    memberGroupsLength(): number {
      if (this.quizEvent && this.quizEvent.memberGroups) {
        return this.quizEvent.memberGroups.length;
      }
      return 0;
    },
    quizGroupsLength(): number {
      if (this.quizEvent && this.quizEvent.quizGroups) {
        return this.quizEvent.quizGroups.length;
      }
      return 0;
    },
    correctSequence(): SentDataRecord[] {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.mapData.sentDataList.sort((a: SentDataRecord, b: SentDataRecord) => {
        if (a.scheduledTime < b.scheduledTime) {
          return 1;
        }
        return -1;
      });
      return this.mapData.sentDataList;
    },
    unsendAreaHeight(): string {
      let num = 0;
      if (this.quizEvent && this.quizEvent.quizGroups) {
        if (this.mapData.unsendDates.length > 1) {
          num = 34;
        } else {
          num = this.quizEvent.quizGroups.length * 34;
        }
      }
      return `${num}px`;
    },
    sentAreaHeight(): string {
      let num = 34;
      if (this.mapData.sentDataList.length > 0) {
        if (this.correctSequence[0].quizGroup.length > 1) {
          num = this.correctSequence[0].quizGroup.length * 34;
        } else {
          num = 34;
        }
      }
      return `${num}px`;
    },
  },
  async mounted() {
    Auth.loginCheck();
    this.$store.commit('updateLoading', true);
    try {
      await QuizEventApi.getQuizEvent(this.quizEventId).then(async (quizEvent) => {
        this.quizEvent = quizEvent;
        this.getQuizEventData();
        console.log('Preview quizEvent:', quizEvent);
      });
    } finally {
      this.$store.commit('updateLoading', false);
    }

    this.getPushedRecord();

    this.getTimeMapData();
  },
  methods: {
    getPushedRecord() {
      this.$store.commit('updateLoading', true);
      if (this.quizEvent && this.quizEvent.pushedQuizGroupList[0]) {
        this.quizEvent.pushedQuizGroupList.forEach(async (record: PushedQuizGroupRecord) => {
          const request = [] as Promise<QuizGroupModel>[];
          record.groupId.forEach((id) => {
            const promies = QuizGroupApi.retrieve(id);
            request.push(promies);
          });

          const quizGroupsArray = await Promise.all(request);
          let object = {} as SentDataRecord;
          object = {
            quizGroup: quizGroupsArray,
            scheduledTime: record.scheduledTime,
            recordCode: record.recordCode,
          };
          this.mapData.sentDataList.push(object as SentDataRecord);
          this.mapData.sentDataList = QuizEventHandler.getSentExamData(this.quizEvent, this.mapData);
        });
      }
      this.$store.commit('updateLoading', false);
    },
    getQuizEventData() {
      if (this.quizEvent) {
        this.scheduleSetting.allowRepeat = !this.quizEvent.onePush;
        this.scheduleSetting.repeatPeriod = this.quizEvent.period;
        this.scheduleSetting.weeklyScheduledDay = this.quizEvent.weekdays;

        if (this.quizEvent.quizGroups
            && this.quizEvent.memberGroups
            && this.quizEvent.quizGroups.length === 0
            && this.quizEvent.memberGroups.length === 0) {
          this.scheduleSetting.allowRepeat = true;
        }

        if (this.quizEvent.validData) {
          this.validData = this.quizEvent.validData;
        }

        if (this.quizEvent.frequency) {
          this.scheduleSetting.repeatFrequency = this.quizEvent.frequency;
        }
      }
    },
    getTimeMapData() {
      this.$store.commit('updateLoading', true);
      if (this.quizEvent && this.quizEvent.timeMap) {
        const result = QuizEventHandler.getMapData(this.quizEvent);
        this.mapData = result.mapData;
        this.scheduleSetting = result.scheduleSetting;
        this.quizEvent = QuizEventHandler.getExamData(this.quizEvent);
      }
      this.$store.commit('updateLoading', false);
    },
    removeGroup() {
      this.openRemoveModal();
    },
    openRemoveModal() {
      this.$modal.show('dialog', {
        title: this.$t('確定刪除嗎?'),
        text: this.$t('刪掉就不會再回來囉'),
        buttons: [
          {
            title: this.$t('取消'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('確定'),
            handler: () => {
              // remove operation
              this.$modal.hide('dialog');
              this.removeGroupCard();
              this.$router.go(-1);
            },
          },
        ],
      });
    },
    async removeGroupCard() {
      if (this.quizEvent) {
        await WorkspaceObjectApi.remove(this.quizEvent.uuid);
      }
    },
    toEventMaker() {
      this.$router.push('/manager/quizevent-maker');
    },
    changeFormat(dateTime: string) {
      const timestamp = Date.parse(dateTime);
      const result = TimeFunction.timestampToDateStringFormat(timestamp / 1000);
      return result;
    },
    // 轉換時間-----------------------------
    displayDateFormat(date: string) {
      const str = TimeFunction.getOnlyDateString(date);
      return str;
    },
    displayTimeFormat(date: string) {
      const timestamp = new Date(date).getTime();
      const rescheduleDate = TimeFunction.getDateStringObject(timestamp / 1000);
      return rescheduleDate.time;
    },
  },
});
