import api from '@/apis/BaseApi';
import { WorkspaceObjectModel } from './models/WorkspaceObjectModel';

// when to trigger type classification? after retrieving data or before retrieving data?
/**
 * (I prefer this)
 * before retrieving data:
 * 0. based on object type, bind different UI, API events
 * 1. click an object, and trigger bind event
 * 2. based on the object type to interpret data (in ui, pages, or code)
 *
 * after retrieving data:
 * 1. click an object
 * 2. use a general api to retrieve object data
 * 3. determine the type of the retrieved data
 * 4. based on the object type to interpret data (in ui, pages, or code)
 *
 * (this is also good)
 * after retrieving data v2:
 * 1. click an object (i know what type the object is)
 * 2. use a general api to retrieve object data
 * 3. based on the object type to interpret data (in ui, pages, or code)
 */

export default class WorkspaceObjectApi {
  /**
   * General retrieve, get the content of a object
   * you can interpret the content based on object type (included in the return value)
   *
   * @param objectId
   * @returns content of a object
   */
  static async retrieve(objectId: string): Promise<WorkspaceObjectModel> {
    const result = await api.get(`workspace/object/${objectId}`);
    return result.data;
  }

  /**
   * Move a object to a folder
   * cannot move a object to its decendants (if exists) or into itself
   *
   * @param objectId
   * @param destinationId the id where the object should move to, should be a folder id
   */
  static async move(objectId: string, destinationId: string): Promise<void> {
    const body = {
      destinationId,
    };
    return api.post(`workspace/object/${objectId}/move`, body);
  }

  /**
   * Shadow copy a object to the same folder
   * NOTE: Nested contents will not be deep-copied!
   *
   * @param objectId
   * @param destinationId the id where the object should move to, should be a folder id
   */
  static async copyQuizGroup(objectId: string): Promise<WorkspaceObjectModel> {
    const body = {};
    const result = await api.post(`workspace/object/${objectId}/copy`, body);
    return result.data.copiedObject;
  }

  static async copyMemberGroup(objectId: string, parentId: string, name: string): Promise<WorkspaceObjectModel> {
    const body = {
      parentId,
      name,
    };
    const result = await api.post(`workspace/member-groups/${objectId}/copy`, body);
    return result.data.memberGroup;
  }

  /**
   * @todo backend not implement
   * Move a object to trash, can be restored later
   * @param objectId
   */
  static async remove(objectId: string): Promise<void> {
    return api.delete(`workspace/object/${objectId}`);
  }

  /**
   * @todo backend not implement
   * Permanently delete a object, cannot be restored later
   * @param objectId
   */
  static async delete(objectId: string): Promise<void> {
    return api.delete(`workspace/object/${objectId}?permanent=true`);
  }
}
